<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de Cobranzas</h4>
            <div class="small text-muted">Consulta de informes de cobranzas</div>
          </b-col>     
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">        
        <b-row v-if="arr.report">
          <b-col md="6">
            <table class="table table-hover table-sm">
              <tbody>
                <tr v-if="arr.report.id">
                  <td class="reports-payment-detail-title-table-custom align-middle">ID</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    <b>{{this.arr.report.id}}</b>
                  </td>
                </tr>            
                <tr>
                  <td class="reports-payment-detail-title-table-custom align-middle">Fecha</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    {{moment(this.arr.report.date).format('DD MMMM YYYY')}}
                  </td>
                </tr>
                <tr>
                  <td class="reports-payment-detail-title-table-custom align-middle">Observaciones</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    {{this.arr.report.observations}}
                  </td>
                </tr>                
                <tr>
                  <td class="reports-payment-detail-title-table-custom align-middle">Importe</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    <b>$ {{this.arr.report.amounts_items}}</b>
                  </td>
                </tr>                                
              </tbody>
            </table>
          </b-col>
          <b-col md="6">
            <table class="table table-hover table-sm">
              <tbody>
                <tr v-if="arr.report.staff_id">
                  <td class="reports-payment-detail-title-table-custom align-middle">Responsable</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    <b-avatar :src="this.arr.report.staff.image"
                              v-if="this.arr.report.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>                         
                    <b-icon icon="blank"></b-icon>
                    <b :title="'STAFF: ' + this.arr.report.staff.name">
                      {{this.arr.report.staff.name}}
                    </b>   
                  </td>
                </tr>      
                <tr v-else>
                  <td class="reports-payment-detail-title-table-custom align-middle">Responsable</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">
                    <b-avatar :src="this.arr.report.seller.staff.image"
                              v-if="this.arr.report.seller.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b :title="'VENDEDOR: ' + this.arr.report.seller.staff.name">
                      {{this.arr.report.seller.staff.name}}
                    </b> 
                  </td>
                </tr>

                <tr v-if="arr.report.customer">
                  <td class="reports-payment-detail-title-table-custom align-middle">Cliente</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">                    
                    <b-avatar :src="this.arr.report.customer.image"
                              v-if="this.arr.report.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b v-if="parameters.codeCustomer">({{this.arr.report.customer.code}}) {{this.arr.report.customer.name}}</b>    
                    <b v-else>{{this.arr.report.customer.name}}</b>
                    <b-icon icon="circle-fill" v-if="!arr.report.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                                       
                  </td>
                </tr>

                <tr v-if="arr.report.surrender_detail">
                  <td class="reports-payment-detail-title-table-custom align-middle">Rendición</td>
                  <td class="reports-payment-detail-value-table-custom align-middle">                      
                    <div class="ml-1">
                      <b-badge v-if="this.arr.report.surrender_detail.status=='Enviado'" variant="info" class="report-payment-surrender-badge">
                        {{this.arr.report.surrender_detail.status}}
                      </b-badge>

                      <b-badge v-if="this.arr.report.surrender_detail.status=='En Revisión'" variant="warning" class="report-payment-surrender-badge">
                        {{this.arr.report.surrender_detail.status}}
                      </b-badge>

                      <b-badge v-if="this.arr.report.surrender_detail.status=='Aprobado'" variant="success" class="report-payment-surrender-badge">
                        {{this.arr.report.surrender_detail.status}}
                      </b-badge>
                      
                      <b-badge v-if="this.arr.report.surrender_detail.status=='Rechazado'" variant="danger" class="report-payment-surrender-badge">
                        {{this.arr.report.surrender_detail.status}}
                      </b-badge>

                      <b-badge v-if="this.arr.report.surrender_detail.status=='No Recibido'" variant="secondary" class="report-payment-surrender-badge">
                        {{this.arr.report.surrender_detail.status}}
                      </b-badge>        
                    </div>            
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-card>
              <div slot="header">
                <i class="icon-docs"></i><strong> Que cancela</strong>
              </div>
              <b-table class="mb-0"
                      responsive="sm"
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="false"
                      :items="arr.report.vouchers"
                      :fields="fields_vouchers"
                      v-if="arr.report">

                    <template v-slot:cell(amount)="data">
                      $ {{data.item.amount}}
                    </template>

              </b-table>

            </b-card>
          </b-col>
          <b-col md="6">
            <b-card>
              <div slot="header">
                <i class="fa fa-money"></i><strong> Desglose de Pago</strong>
              </div>                              
              
              <b-table class="mb-0"
                  responsive="sm"
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="false"
                  :items="arr.report.items"
                  :fields="fields_payment"
                  v-if="arr.report">
                
                <template v-slot:cell(id)="data">
                  <strong>{{data.item.id}}</strong>
                </template>
                
                <template v-slot:cell(type)="data">
                  {{data.item.type}}
                </template>
                                
                <template v-slot:cell(check)="data">
                  <div v-if="data.item.type=='Cheque'">                    
                    <div :id="'popover-target-' + data.index" class="badge badge-secondary text-wrap popover-size-custom">
                      Más Info Cheque
                    </div>
                    
                    <b-popover :target="'popover-target-' + data.index" title="Info Cheque" triggers="hover" placement="left">                    
                      <table>                      
                        <tr>
                          <td class="reports-payment-popover-header-item">Tipo</td>
                          <td>{{data.item.check_type.toUpperCase()}}</td>
                        </tr>                      
                        <tr>
                          <td class="reports-payment-popover-header-item">Banco</td>
                          <td>{{data.item.check_bank}}</td>
                        </tr>
                        <tr>                                                 
                        <tr>
                          <td class="reports-payment-popover-header-item">Número</td>
                          <td>{{data.item.check_number}}</td>
                        </tr> 
                        <tr>
                          <td class="reports-payment-popover-header-item">Fecha Movimiento</td>
                          <td>
                            <div v-if="data.item.check_date_movement">
                              {{moment(data.item.check_date_movement).format('DD/MM/YYYY')}}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="reports-payment-popover-header-item">CUIT</td>
                          <td>{{data.item.check_cuit}}</td>
                        </tr>                      
                        <tr>
                          <td class="reports-payment-popover-header-item">Observaciones</td>
                          <td>{{data.item.check_observations}}</td>
                        </tr>                        
                      </table>
                    </b-popover>                      
                  </div>    
                </template>
              
                <template v-slot:cell(amount)="data">
                  <div class="reports-payment-text-red" v-if="data.item.type=='Nota de Débito'">
                    -$ {{ parseFloat(data.item.amount).toFixed(2) }}
                  </div>
                  <div class="reports-payment-text-green" v-else>
                    $ {{ parseFloat(data.item.amount).toFixed(2) }}
                  </div>
                </template>  
                
              </b-table>

            </b-card>
          </b-col>
        </b-row>

      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Param from '@/config/parameters'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_COBRANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailReport',
          elements: {}
        }, 
        parameters: {
          codeCustomer: Helper.hasParametersAccess(Param.P2)
        },          
        reportID: 0,
        fields_payment: [
          {key: 'id', label: 'ID', sortable: true},
          {key: 'type', label: 'Medio'},  
          {key: 'check', label: 'Info Extra', class:'text-center'},
          {key: 'amount', label: 'Importe', class:'text-center'},          
        ],
        fields_vouchers: [                    
          {key: 'date', label: 'Fecha', class:'text-left'},
          {key: 'vouchers', label: 'Comprobante', class:'text-center'},
          {key: 'amount', label: 'Importe', class:'text-center'},          
        ],           
        arr: {
          report: null
        }     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.reportID = this.$route.params.reportID

      this.load()
    },
    methods: {
      validation() {        
        var seller = Helper.getSeller()                        
        if(seller) {          
          if(this.arr.report.sellers_id != seller.id) {
            this.$router.push({ name: 'Error403' })
          }
        }
      },      
      load() {
        var result = serviceAPI.mostrar(this.reportID)

        result.then((response) => {
          var data = response.data
          this.arr.report = data

          // VALIDACION DE VISUALIZACION
          this.validation()
          // FIN VALIDACION          
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },    
    }    
  }
</script>
<style>
  .reports-payment-detail-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 25%;
    text-align: right
  }
  .reports-payment-detail-value-table-custom {
    padding-left: 5px;
  }

  .reports-payment-popover-header-item {  
    font-size: 14px !important;
    font-weight: 700 !important;   
    padding-right: 5px !important;      
  }
  .reports-payment-text-red {
    color: red;    
  }
  .reports-payment-text-green {
    color: green;    
  }    
</style>